<template>
  <a-row class="demo-detail">
    <a-breadcrumb class="a-bread-sty" separator=">">
      <a-breadcrumb-item v-for="item in breadList" :key="item.name">
        <router-link :to="{ path: item.path === '' ? '/' : item.path }">{{
          item.meta.title
        }}</router-link>
      </a-breadcrumb-item>
    </a-breadcrumb>
    <h2 align="center">粮油行业控制系统</h2>

    <div class="top">
      <p>
        MES系统可以提供全面的生产过程监控和数据分析，帮助企业实现智能化、数字化生产。同时，MES系统可以优化生产计划和物料配送，降低废品率和能耗，提高生产效率和质量，降低生产成本，增加利润。
      </p>
      <img src="../../assets/image/adminName.png" />
    </div>
    <div class="proj-role">
      <p class="proj-one">项目作用</p>
      <p class="proj-t">
        1.追溯性：要求系统能够对生产过程中的每个环节进行追溯和记录，以满足食品安全和质量管理的需要。
        2.批次管理：可以对原材料、半成品和成品进行批次管理，从而提高产品质量和生产效率。
        3.工艺管理：对生产过程中的各个工艺进行管理，控制每个工艺的参数和流程，以确保产品的质量和稳定性。
        4.质量管理：包括检验、检测、统计和分析等方面，对原材料、半成品和成品进行全面的质量管理。
      </p>
    </div>

    <h2 align="center">粮油行业控制系统</h2>
    <p class="to-p" align="center">
      粮油行业自动化控制系统解决方案，赋能粮油行业提高生产效率和质量一致性。
    </p>

    <div class="swiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="item in planList"
          :key="item.id"
          @mouseover="switchItem(item)"
        >
          <img :src="item.img" />
        </div>
      </div>
    </div>

    <!-- <h2 align="center">项目亮点</h2>
    <div class="bottom-box">
      <img class="box-img" src="../../assets/image/32650.png">
      <ul class="list">
        <li>集中管控各生产线生产
          流程，减少了中间环节 </li>
        <li>报错机制，生产过程中
          的问题会有及时响应，提高生产效率。</li>
        <li>集中管控各生产线生
          产流程，减少了中间环节 </li>
        <li>报错机制，生产过程中的
          问题会有及时响应，提高生产效率。</li>
      </ul>
    </div> -->
  </a-row>
</template>

<script>
import Swiper from "../../../node_modules/swiper/swiper-bundle.js";
export default {
  mounted() {
    this.getBreadcrumb();
    var mySwiper = new Swiper(".swiper", {
      direction: "horizontal", //
      loop: true,
      breakpoints: {
        320: {
          //当屏幕宽度大于等于320
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          //当屏幕宽度大于等于768
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1280: {
          //当屏幕宽度大于等于1280
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
      autoplay: {
        delay: 1000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      disableOnInteraction: false,
    });
  },
  data() {
    return {
      planList: [
        {
          id: 1,
          img: require("../../assets/image/egrain1.png"),
        },
        {
          id: 2,
          img: require("../../assets/image/egrain2.png"),
        },
        {
          id: 3,
          img: require("../../assets/image/egrain1.png"),
        },
        {
          id: 4,
          img: require("../../assets/image/egrain2.png"),
        },
      ],

      breadName: "",
      breadList: [],
    };
  },
  methods: {
    getBreadcrumb() {
      this.breadList = [];
      this.breadName = this.$route.name;
      this.$route.matched.forEach((ele, index, arr) => {
        this.breadList.push(ele);
      });
    },
  },
};
</script>

<style lang="less">
.demo-detail {
  margin: 0 auto;
  h2 {
    margin-top: 120px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
  }
  .a-bread-sty {
    width: 1440px;
    margin: 40px auto;
    font-size: 20px;
  }
  .top {
    width: 1440px;
    margin: 0 auto;
    margin-top: 140px;
    padding: 51px;
    background: linear-gradient(#f9fafe, #e7eeff);
    border-radius: 20px;
    box-shadow: 0 10px 20px #cccccc;
    display: flex;
    align-items: center;
    img {
      width: 200px;
      height: 300px;
    }
  }
  .to-p {
    margin-bottom: 40px;
  }
  .swiper {
    width: 1440px;
    border-radius: 20px;
    .swiper-slide {
      img {
        width: 100%;
        height: 200px;
      }
    }
  }
  .proj-role {
    margin: 0 auto;
    margin-top: 150px;
    width: 1440px;
    border-radius: 25px;
    padding: 50px;
    color: #ffffff;
    // background: linear-gradient(to right, #ff6600, #ff9354);
    background: url("../../assets/image/32647.png") no-repeat;
    background-size: cover;
    box-shadow: 0 10px 20px #cccccc;
    .proj-one {
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }
    .proj-t {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
    }
  }
  .bottom-box {
    width: 100%;
    background: url("../../assets/image/32651.png");
    text-align: center;

    .box-img {
      width: 1440px;
      height: 529px;
    }
    .list {
      margin: 0 auto;
      width: 1440px;

      display: flex;
      justify-content: space-between;
      padding-bottom: 50px;
      li {
        width: 20%;
        &:nth-child(1),
        &:nth-child(4) {
          margin-top: -220px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          margin-top: -50px;
        }
      }
    }
  }
}

@media all and (max-width: 700px) {
    .demo-detail {
      margin: 0 auto;
      padding: 0 200px;
      h2 {
        margin-top: 120px;
        font-size: 80px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }
  
      .a-bread-sty {
        width: 1440px;
        margin: 40px auto;
        font-size: 40px;
      }
  
      .top {
        width: 1440px;
        margin: 0 auto;
        margin-top: 140px;
        padding: 51px;
        background: linear-gradient(#f9fafe, #e7eeff);
        border-radius: 20px;
        box-shadow: 0 10px 20px #cccccc;
        display: flex;
        align-items: center;
  
        img {
          width: 200px;
          height: 300px;
        }
        p {
          font-size: 48px;
        }
      }
  
      .to-p {
        margin-bottom: 40px;
        font-size: 48px;
      }
  
      .swiper {
        width: 1440px;
        border-radius: 20px;
  
        .swiper-slide {
          img {
            width: 100%;
            height: 200px;
          }
        }
      }
  
      .proj-role {
        margin: 0 auto;
        margin-top: 150px;
        width: 1440px;
        border-radius: 25px;
        padding: 50px;
        color: #ffffff;
        background: url("../../assets/image/32647.png") no-repeat;
        background-size: cover;
        box-shadow: 0 10px 20px #cccccc;
  
        .proj-one {
          font-size: 80px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
  
        .proj-t {
          font-size: 48px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }
      }
  
      .bottom-box {
        width: 100%;
        background: url("../../assets/image/32651.png");
        text-align: center;
  
        .box-img {
          width: 1440px;
          height: 529px;
        }
  
        .list {
          margin: 0 auto;
          width: 1440px;
  
          display: flex;
          justify-content: space-between;
          padding-bottom: 50px;
  
          li {
            width: 20%;
  
            &:nth-child(1),
            &:nth-child(4) {
              margin-top: -220px;
            }
  
            &:nth-child(2),
            &:nth-child(3) {
              margin-top: -50px;
            }
          }
        }
      }
    }
  }
</style>
